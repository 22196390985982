@import 'src/design-system/styles/typography.mixins';

.container {
  background: var(--shape-10);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: transform .3s ease;

  &:hover {
    transform: scale(1.03);
  }
}

.image {
  width: 100%;
}

.title {
  @include font-style-default($font-weight-bold);
  padding: 8px 8px 8px 12px;
  color: var(--text-10);
  margin: 0;
}
